import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { useCssVars as _useCssVars, unref as _unref } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
import { useI18n } from 'vue-i18n';
import { router } from '../src/router';
import { provide, ref, nextTick } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    _useCssVars(_ctx => ({
      "8f699c4e": theme.fontFamily
    }));
    const isRouterAlive = ref(true);
    var reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide('reload', reload);
    const {
      t
    } = useI18n();
    const u = navigator.userAgent.toLowerCase();
    const theme = {
      fontFamily: 'PingFang SC'
    };
    var isSupportFontFamily = function (f) {
      if (typeof f != "string") {
        return false;
      }
      var h = "Arial";
      if (f.toLowerCase() == h.toLowerCase()) {
        return true;
      }
      var e = "a";
      var d = 100;
      var a = 100,
        i = 100;
      var c = document.createElement("canvas");
      var b = c.getContext("2d");
      c.width = a;
      c.height = i;
      b.textAlign = "center";
      b.fillStyle = "black";
      b.textBaseline = "middle";
      var g = function (j) {
        b.clearRect(0, 0, a, i);
        b.font = d + "px " + j + ", " + h;
        b.fillText(e, a / 2, i / 2);
        var k = b.getImageData(0, 0, a, i).data;
        return [].slice.call(k).filter(function (l) {
          return l != 0;
        });
      };
      return g(h).join("") !== g(f).join("");
    };
    var computedStyles = 'getComputedStyle' in window ? window.getComputedStyle(document.body) : document.body.currentStyle;
    const sysfont = computedStyles['font-family'];
    var isiOS = function (us) {
      if (us.indexOf('ios') > -1) {
        return true;
      } else if (us.indexOf('ipad') > -1) {
        return true;
      } else if (us.indexOf('mac') > -1) {
        return true;
      } else if (us.indexOf('iphone') > -1) {
        return true;
      } else {
        return false;
      }
    };
    var isWeixin = function (us) {
      if (us.indexOf('micromessenger') > -1) {
        return true;
      } else {
        return false;
      }
    };
    if (isWeixin(u)) {
      localStorage.setItem('weixin', "open");
    } else {
      localStorage.setItem('weixin', "close");
    }
    if (isiOS(u)) {
      if (localStorage.getItem('lang') == 'zh') {
        if (isSupportFontFamily('PingFang SC')) {
          theme.fontFamily = 'PingFang SC';
        } else {
          theme.fontFamily = sysfont;
        }
      } else {
        {
          theme.fontFamily = 'TWDC-Roman';
        }
      }
    } else {
      if (localStorage.getItem('lang') == 'zh') {
        if (isSupportFontFamily('Source Han Sans CN')) {
          theme.fontFamily = 'Source Han Sans CN';
        } else {
          theme.fontFamily = sysfont;
        }
      } else {
        {
          theme.fontFamily = 'TWDC-Roman';
        }
      }
    }
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [isRouterAlive.value ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : _createCommentVNode("", true)]);
    };
  }
};